import React from 'react';
import Header from './header';
import Navigation from './navigation';
// import Footer from './footer';

function Layout({ children }) {
  return (
    <div className="layout">
      <Header />
      <Navigation />
      <main className="flex flex-col overflow-auto p-4 px-4 sm:px-10 max-w-full h-full">
        {children}
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;