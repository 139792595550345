import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function Navigation() {
  const NAV_LINKS = [
    { to: "/about", label: "About" },
    { to: "/articles", label: "Articles" },
    { to: "/projects", label: "Projects" },
  ];
  
  const PAGE_COLORS = {
    "/about": { default: "text-color-primary", active: "text-color-normal hover:underline hover:text-color-secondary" },
    "/articles": { default: "text-color-primary", active: "text-color-normal hover:underline hover:text-color-secondary" },
    "/projects": { default: "text-color-primary", active: "text-color-normal hover:underline hover:text-color-secondary" },
  };

  const { pathname } = useLocation();
  const history = useNavigate();
  const url = window.location.href;

  return (
    <div className="navigation px-4 sm:px-8 flex justify-between">
  <div className="p-2 sm:p-4 text-base font-medium">
    {NAV_LINKS.map((link) => (
      <React.Fragment key={link.to}>
        <NavLink
          to={link.to}
          className={`${pathname.includes(link.to) ? PAGE_COLORS[link.to].active : PAGE_COLORS[link.to].default} hover:underline hover:text-color-normal`}
        >
          {link.label}
        </NavLink>
        <span className="px-2 sm:px-4 text-color-secondary">||</span>
      </React.Fragment>
    ))}
    <a
      href="https://tunkhine126.files.wordpress.com/2024/02/tk-resume_2_22.pdf"
      target="_blank"
      rel="noopener noreferrer"
      className="text-color-primary hover:underline hover:text-color-normal"
    >
      Resume
    </a>
  </div>
  {url.includes('projects/') && (
    <button className="mr-auto pl-6" onClick={() => history(-1)}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#209BF1" className="w-8 h-8 hover:scale-110">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </button>
  )}
</div>
  );
}

export default Navigation;