import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout';
import About from './components/pages/about'
import Articles from './components/pages/articles';
import Projects from './components/pages/projects';
import Project from './components/pages/project';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={ <About /> } />
          <Route path="/about" element={ <About /> } />
          <Route path="/articles" element={ <Articles /> } />
          <Route path="/projects" element={ <Projects /> } />
          <Route path="/projects/:slug" element={<Project />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;