import React from 'react';
import ProjectData from '../data/projectData'


function Projects() {
  // path to project
  const projectPath = (slug) => {
    window.location = '/projects/'+ slug
  };

  return(
  <div className="content-wrapper px-2 md:max-w-2xl">
    <h5 className="text-lg font-medium pb-2 text-color-normal">Projects</h5>
    <div className="content">
      <div className="overflow-hidden">
        <table className="min-w-full">
            <thead className="text-white bg-slate-300">
              <tr>
                <th scope="col" className="p-4 text-xs font-semibold tracking-wider text-left uppercase">
                  Project
                </th>
                <th scope="col" className="hidden sm:block p-4 text-xs font-semibold tracking-wider text-left uppercase flex-wrap">
                  Description
                </th>
                <th scope="col" className="p-4 text-xs font-semibold tracking-wider text-left uppercase">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-900">
              {ProjectData.map((project, idx) => (
                <tr key={idx}>
                  <td className="p-4 text-sm font-normal">
                    <a href={`${'/projects/'+ project.slug}`} onClick={() => projectPath(project.slug)}className="text-color-primary hover:text-color-normal">
                      {project.title}
                    </a>
                  </td>
                  <td className="hidden sm:block p-4 text-sm font-normal flex-wrap">
                    {project.description}
                  </td>
                  <td className="p-4 text-sm font-normal whitespace-nowrap">
                    <span className={`${project.status === 'inactive' ? 'text-color-error' : 'text-color-normal'} px-2`}>
                      {project.status === 'inactive' ? `⚰️ ${project.status}`  : `👨🏽‍💻 ${project.status}`}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Projects;