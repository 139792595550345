import React from 'react';

function About() {
  return(
    <div className="content-wrapper px-2 md:max-w-2xl">
      <h5 className="text-xl font-medium pb-2 text-color-normal">About Me</h5>
      <div className="content">
        <p>Hi, I’m Tun! I'm a freelance developer building <a href="https://github.com/tunkhine126" target="_blank" rel="noreferrer" className='font-medium text-color-primary hover:underline hover:text-color-normal'>software</a>, legos with my kiddos, and also riding bikes - and have a ton of fun doing all of the above.</p>

        <p className='py-2'>
          I have always been a curious and hands-on person, and my passion for tinkering has been a constant throughout my life. After my first career in Peacekeeping for the <a href="https://peacekeeping.un.org/en" target="_blank" rel="noreferrer" className='font-medium text-color-primary hover:underline hover:text-color-normal'>United Nations</a>, where I gained a deep appreciation for the value of <a href="https://ebolaresponse.un.org/un-mission-ebola-emergency-response-unmeer" target="_blank" rel="noreferrer" className='font-medium text-color-primary hover:underline hover:text-color-normal'>impactful</a> work, I transitioned to coding and have been doing so professionally for the past four years. During this time, I've had the opportunity to work with companies such as <a href="https://newstoryhomes.org/" target="_blank" rel="noreferrer" className='font-medium text-color-primary hover:underline hover:text-color-normal'>New Story</a>, <a href="https://www.mialma.com/" target="_blank" rel="noreferrer" className='font-medium text-color-primary hover:underline hover:text-color-normal'>Mi Alma</a>, and <a href="https://www.weareclever.com/" target="_blank" rel="noreferrer" className='font-medium text-color-primary hover:underline hover:text-color-normal'>Clever</a>, where I have continued to learn from the best in the field.
        </p>
        
        <p className='py-2'>
          It's my personal mission to build high-quality and innovative software solutions that empower businesses and individuals.
        </p>

        <p className='py-2'>
          If you are interested in working together, shoot me a <a href="mailto:tunkhine126@gmail.com" className='font-medium text-color-primary hover:underline hover:text-color-normal'>message</a>!
        </p>
      </div>
    </div>
  )
}

export default About;