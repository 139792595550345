const ProjectData = [
  {
    title: "Quse",
    slug: "quse",
    img_url: "https://tunkhine126.files.wordpress.com/2023/07/quse.png",
    description: "A new way to see your money. Keep you bank and budget in line.",
    framework: "Ruby, Ruby on Rails, React Native",
    project_url: "https://www.myquse.com/",
    status: "active"
  },
  {
    title: "Clever Marketing",
    slug: "clever-marketing",
    img_url: "https://user-images.githubusercontent.com/19766367/233662918-8b6aea69-c727-4d36-9dd1-03bb5c2662da.png",
    description: "Clever Marketing's website",
    framework: "Hubspot and Javascript",
    project_url: "https://www.weareclever.com/",
    status: "active"
  },
  {
    title: "Mi Alma",
    slug: "mi-alma",
    img_url: "https://user-images.githubusercontent.com/19766367/221728502-6644d505-59fe-41b6-a805-8c7de22eaf1f.png",
    description: "By mobilizing communities to memorialize legacies, Mi Alma is setting the modern standard for navigating life after loss. Mi Alma is the place to support grievers. A modern home where grievers can forever celebrate lost loved ones by creating a page that includes: Support Registry, Memories, and Family Messages",
    framework: "Ruby on Rails",
    project_url: "https://www.mialma.com/",
    status: "active"
  },
  {
    title: "Rails 7 Kickoff App",
    slug: "rails-kickoff",
    img_url: "https://user-images.githubusercontent.com/19766367/216463202-70b61b9c-6e34-4f5a-b451-8e073cb9d738.png",
    description: "A rapid Rails 7 application template.",
    framework: "Ruby on Rails",
    project_url: "https://github.com/tunkhine126/Rails-kickoff-app",
    status: "active"
  },
  {
    title: "Felix",
    slug: "felix",
    img_url: "https://user-images.githubusercontent.com/19766367/221730591-3343a781-c3b2-4873-b9cf-fa9e4ec9d0e5.png",
    description: "A rapid data collection and analysis platform powered by housing nonprofit New Story. Felix helps leaders identify the current needs in their community, track trends over time, and deliver actionable insights to build trust and maximize resources.",
    framework: "Ruby on Rails and React Native",
    project_url: "https://apps.apple.com/us/app/felix-by-new-story/id1454702115",
    status: "active"
  },
  {
    title: "NS Donate",
    slug: "ns-donate",
    img_url: "https://user-images.githubusercontent.com/19766367/214605934-01980f67-e2be-4354-bcf3-cc193153e22f.png",
    description: "New Story's donation platform",
    framework: "Ruby on Rails and React",
    project_url: "https://donate.newstoryhomes.org/",
    status: "active"
  },
  {
    title: "Tweeter",
    slug: "tweeter",
    img_url: "https://user-images.githubusercontent.com/19766367/214355126-af8235a2-73f3-4f60-8ac3-cba25d5ab8dd.png",
    description: "A twitter clone built in Rails 7 using Tailwind CSS",
    framework: "Ruby on Rails",
    project_url: "https://sheltered-bastion-65763.herokuapp.com/users/sign_in",
    content: "https://github.com/tunkhine126/Tweeter",
    status: "active"
  },
  {
    title: "XKCD Web Comic App",
    slug: "xkcd-web-comic-app",
    img_url: "https://github.com/tunkhine126/Portfolio/blob/master/src/Images/xkcd.png?raw=true",
    description: "New Story Charity's assessment for an Engineering Role",
    framework: "Created using React and Cypress for testing. Used the XKCD API to display comics.",
    project_url: "https://xkcd-webcomic-app.firebaseapp.com/",
    status: "active"
  },
  {
    title: "Expo Weather App",
    slug: "expo-weather-app",
    img_url: "https://github.com/tunkhine126/Expo-Weather-App/blob/master/assets/Screen%20Shot%202019-10-20%20at%202.41.15%20PM.png?raw=true",
    description: "A simple weather app built using React Native and Expo.",
    framework: "Created using React Native and Expo. Used the OpenWeather API to display weather information.",
    project_url: "https://github.com/tunkhine126/Expo-Weather-App",
    status: "inactive"
  },
  {
    title: "Salesloft Simple App",
    slug: "salesloft-simple-app",
    img_url: "https://raw.githubusercontent.com/tunkhine126/Portfolio/master/src/Images/Screen%20Shot%202019-09-21%20at%201.08.31%20PM.png",
    description: "Third part of an assessment for an engineering role at SalesLoft.",
    framework: "Created using React. Using the SalesLoft API to display and complete the requiste tasks.",
    project_url: "https://salesloftsimple-web-app.web.app/",
    status: "inactive"
  },
  {
    title: "React portfolio",
    slug: "react-portfolio",
    img_url: "https://raw.githubusercontent.com/tunkhine126/Portfolio/master/src/Images/tk_portfolio.png",
    description: "My orginal personal portfolio site to showcase projects and articles.",
    framework: "Created using React",
    project_url: "https://github.com/tunkhine126/Portfolio",
    credentials: "",
    status: "inactive"
  },
  {
    title: "SHRED",
    slug: "shred",
    img_url: "https://raw.githubusercontent.com/tunkhine126/Portfolio/master/src/Images/shred.png",
    description: "A social fitness network for mountain bikers to track trails you've “shred”",
    framework: "The backend is built using a Ruby on Rails while the frontend is built on React-Redux.",
    project_url: "https://shred-frontend.firebaseapp.com/",
    credentials: "Usertitle: TestUser | Password: 1234",
    status: "inactive"
  },
  {
    title: "Petlanta Paws",
    slug: "petlata-paws",
    img_url: "https://raw.githubusercontent.com/tunkhine126/Portfolio/master/src/Images/petlanta_paws.png", 
    description: "A pet adoption web app, where users can view and adopt an animal to love.",
    framework: "Created using Ruby on Rails",
    project_url: "https://petlanta-paws-app.herokuapp.com/login",
    credentials: "Usertitle: TestUser | Password: 1234",
    status: "inactive"
  }
]

export default ProjectData;